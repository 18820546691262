$rebranding-font-path: '../fonts' !default;
$image-path: '/img' !default;

@import '@totalenergiescode/springbox-branding-theme-total-energies/lib/scss/base';
@import '@totalenergiescode/springbox-branding-theme-total-energies/lib/scss/typography';
@import '@totalenergiescode/springbox-branding-theme-total-energies/lib/scss/components';

@supports (-webkit-touch-callout: none) {
  strong {
    @apply font-bold;
  }
  body * {
    -webkit-font-smoothing: subpixel-antialiased !important;
    text-rendering: optimizeLegibility !important;
  }
}

html {
  height: 100vh;
}

html,
body {
  background-color: var(--color-grey-light);
  height: 100vh;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
